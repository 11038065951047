"use strict";

var is_touch_device = function() {
	return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
};
is_touch_device() ? jQuery('html').addClass('touch-device') : jQuery('html').addClass('no-touch-device');

jQuery(function() {

	jQuery.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
		}
	});

	var astres;

	// for offline support
	// https://github.com/hubspot/offline
	jQuery.getJSON(jQuery('meta[name="astres"]').attr('content'), function(data) {
		astres = data;
	});

	// HP + Form
	if (jQuery('#astreForm').length) {

		var cleave = new Cleave('#birthdate', {
			date: true,
			datePattern: ['d', 'm', 'Y']
		});

		jQuery('#astreForm').on('submit', function(e) {
			jQuery.post('/save', {
				birthdate: jQuery('#birthdate').val()
			}, function(data) {
				History.pushState(null, null, "/astre/" + data.sign + '#' + data.hash);

				jQuery('#astre-container').remove();
				jQuery('.wrapper_homepage').remove();
				jQuery('.container--main').append(data.view);
				jQuery('body').attr('class', 'astre');

				setShare(data.sign_name)
			});
			e.preventDefault();
		});

		jQuery('#birthdate').focus();
	}

	// SHARE
	var character = "",
		text = "",
		url;

	var setShare = function(c) {
		character = c;
		var text_facebook = "Je suis né-e sous l’astre " + character + ". Et vous ? \n Découvrez quel personnage d'Hergé guide vos pas célestes.";
		var text_twitter = "Mon guide spirituel est désormais " + character + " #ExpoHergé ";
		url = document.URL.replace(/#.*$/, '');

		jQuery('.bt-whatsapp').data('action', "share/whatsapp/share");
		jQuery('.bt-whatsapp').attr('href', 'whatsapp://send?text=' + encodeURIComponent(url) + ' ' + encodeURIComponent(text));

		jQuery('.bt-facebook').on('click', function(e) {
			e.preventDefault();
			window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(url) + '&amp;t=' + encodeURIComponent(text_facebook), 'rmnfacebook', 'status=1,width=580,height=400,top=' + ($(window).height() - 400) / 2 + ',left=' + ($(window).width() - 580) / 2 + ',scrollbars=no');
		});
		jQuery('.bt-twitter').on('click', function(e) {
			e.preventDefault();
			window.open('https://twitter.com/share?url=' + encodeURIComponent(url) + '&amp;text=' + encodeURIComponent(text_twitter), 'rmntwitter', 'status=1,width=580,height=400,top=' + ($(window).height() - 400) / 2 + ',left=' + ($(window).width() - 580) / 2 + ',scrollbars=no');
		});

	};

	if (jQuery('.astre__header').length) {
		setShare(jQuery(".astre__header__figure").attr('alt'));
	}

	// jQuery('.bt-whatsapp').on('click', function(e) {
	// 	e.preventDefault();
	// 	window.document.location = 'data-action="share/whatsapp/share" whatsapp://send?text=' + encodeURIComponent(url) + ' ' + encodeURIComponent(text);
	// });

	// A PROPOS - POP IN
	jQuery(document).on('click', '#about-link', function(event) {
		jQuery('#about').fadeIn();
		event.preventDefault();
	});

	jQuery(document).on('click', '.bt_close', function(event) {
		jQuery('#about').fadeOut();
		event.preventDefault();
	});
});